// Demande une confirmation si l'utilisateur souhaite supprimer un dossier
export const dossierDeleteLink = () => {
	if (document.getElementById("dossier-delete-link")) {
    const deleteLink = document.getElementById("dossier-delete-link");

    document.body.addEventListener('click', () => {
      deleteLink.textContent = "Effacer ce dossier";
    });
		deleteLink.addEventListener('click', e => {
      e.stopPropagation();
      if (deleteLink.textContent != "Supprimer définitivement ?") {
        e.preventDefault();
      }
      deleteLink.textContent = "Supprimer définitivement ?";
    });
	} else {
		return;
	}
};
