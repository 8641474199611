// Ce module permet à la page acc.php de communiquer pour mettre dynamiquement les informations à jour

// Cette fonction travaille avec la page serveur _get_student_ens.php, qui va réceptionner la requête
export const getStudentEns = (id_eleve, elementReceptacle) => {
  let request = new XMLHttpRequest();
  request.onreadystatechange = () => {
    if (request.readyState === 4) {
      if (request.status === 200) {
        //return request.responseText; Doesn't work, why?
        elementReceptacle.innerHTML = request.responseText;
      }
    }
  };

  request.open("GET", "_get_student_ens.php?id=" + id_eleve);
  request.send();
};

// Cette fonction travaille avec la page serveur _get_student_ens.php, qui va réceptionner la requête
export const getProfDates = (id_prof, elementReceptacle) => {
  let request = new XMLHttpRequest();
  request.onreadystatechange = () => {
    if (request.readyState === 4) {
      if (request.status === 200) {
        //return request.responseText; Doesn't work, why?
        elementReceptacle.innerHTML = request.responseText;
      }
    }
  };

  request.open("GET", "_get_prof_dates.php?id=" + id_prof);
  request.send();
};
