// Permet d'afficher un autre display si on clique sur un créneau. Gère aussi l'ajax et le reste de cette page
import { getStudentEns, getProfDates } from './_ajax_acc';

const accompagnements = () => {
  if (!document.getElementsByClassName('acc_creneau')) {
    return;
  } else {

    // Ajout de l'ID du prof accompagnateur sélectionné en hidden dans les deux formulaires
    if (document.getElementById('acc_prof')) {
      const hiddenInputProfId = document.querySelectorAll('[data-acc-prof-id]');
      const accProfDroplist = document.getElementById('acc_prof');
      let profSelectedAtFirst = accProfDroplist.value;
      for (let currentHiddenInputProf of hiddenInputProfId) {
        currentHiddenInputProf.setAttribute('value', accProfDroplist.value);
      }
      accProfDroplist.addEventListener('change', e => {
        for (let currentHiddenInputProf of hiddenInputProfId) {
          currentHiddenInputProf.setAttribute('value', e.target.value);
        }

        // Mise à jour de la liste des profs
        getProfDates(e.target.value, document.querySelector('[data-acc-ajax-dates]'));

        // disable results if selected prof is not the original
        if (e.target.value != profSelectedAtFirst) {
          document.getElementById('acc_results').style.visibility = "hidden";
        } else {
          document.getElementById('acc_results').style.visibility = "visible";
        }
      });
    }

    // disable result if day changed
    if (document.querySelector(['[data-acc-ajax-dates]'])) {
      let dateSelect = document.querySelector('[data-acc-ajax-dates]');
      let dateSelectedAtFirst = dateSelect.value;

      dateSelect.addEventListener('change', e => {
        if (e.target.value != dateSelectedAtFirst) {
          document.getElementById('acc_results').style.visibility = "hidden";
        } else {
          document.getElementById('acc_results').style.visibility = "visible";
        }
      });
    }

    // disable result if calendar
    if (document.getElementById('acc_date')) {
      let calendarSelect = document.getElementById('acc_date');
      let calendarSelectedAtFirst = calendarSelect.value;

      calendarSelect.addEventListener('change', e => {
        if (e.target.value != calendarSelectedAtFirst) {
          document.getElementById('acc_results').style.visibility = "hidden";
        } else {
          document.getElementById('acc_results').style.visibility = "visible";
        }
      });
    }

    // Ajouter un premier créneau : griser le bouton si on déborde
    if (document.querySelector('[data-start-of-first-creneau]')) {
      const firstCreneauForm = document.querySelector('[data-first-creneau-form]');
      const firstH = firstCreneauForm.querySelector('[name="acc_start_hour"]');
      const firstMin = firstCreneauForm.querySelector('[name="acc_start_min"]');
      const firstLenght = firstCreneauForm.querySelector('[name="acc_lenght"]');
      const addition = document.querySelector('[data-start-of-first-creneau]').getAttribute('data-start-of-first-creneau');
      const submit = firstCreneauForm.querySelector('[type="submit"]');
      let h = Number(firstH.value) * 60;
      let m = Number(firstMin.value);
      let l = Number(firstLenght.value);

      submit.addEventListener('click', e => {
        if (submit.classList.contains('button_forbidden')) {
          e.preventDefault();
        }
      });

      const disableSubmit = () => {
        submit.classList.replace('button_valid', 'button_forbidden');
      };
      const enableSubmit = () => {
        submit.classList.replace('button_forbidden', 'button_valid');
      };

      if (h + m + l > addition) {
        disableSubmit();
      }

      firstH.addEventListener('input', e => {
        h = Number(e.target.value) * 60;

        if (h + m + l > addition) {
          disableSubmit();
        } else {
          enableSubmit();
        }
      });

      firstMin.addEventListener('input', e => {
        m = Number(e.target.value);

        if (h + m + l > addition) {
          disableSubmit();
        } else {
          enableSubmit();
        }
      });

      firstLenght.addEventListener('input', e => {
        l = Number(e.target.value);

        if (h + m + l > addition) {
          disableSubmit();
        } else {
          enableSubmit();
        }
      });
    }

    // Ajouter un créneau intermediaire : griser le bouton si on déborde
    // /!\ Attention reader ! Ce code est pourrait largement être simplifié dans le sens où c'est l'exact identique
    // de ce qu'il y a au dessus.... Please be gentle, it's my first JS project, and I'll simplify it over time anyway...
    if (document.querySelector('[data-add-intermediaire]')) {
      const blocIntermediaire = document.querySelectorAll('[data-add-intermediaire]');

      for (let current_intermediaire of blocIntermediaire) {
        const intermediaireCreneauForm = current_intermediaire.querySelector('form');
        const firstH = intermediaireCreneauForm.querySelector('[name="acc_start_hour"]');
        const firstMin = intermediaireCreneauForm.querySelector('[name="acc_start_min"]');
        const firstLenght = intermediaireCreneauForm.querySelector('[name="acc_lenght"]');
        const addition = current_intermediaire.querySelector('[data-start-of-next-creneau]').getAttribute('data-start-of-next-creneau');
        const submit = current_intermediaire.querySelector('[type="submit"]');
        let h = Number(firstH.value) * 60;
        let m = Number(firstMin.value);
        let l = Number(firstLenght.value);

        submit.addEventListener('click', e => {
          if (submit.classList.contains('button_forbidden')) {
            e.preventDefault();
          }
        });

        const disableSubmit = () => {
          submit.classList.replace('button_valid', 'button_forbidden');
        };
        const enableSubmit = () => {
          submit.classList.replace('button_forbidden', 'button_valid');
        };

        if (h + m + l > addition) {
          disableSubmit();
        }

        firstH.addEventListener('input', e => {
          h = Number(e.target.value) * 60;

          if (h + m + l > addition) {
            disableSubmit();
          } else {
            enableSubmit();
          }
        });

        firstMin.addEventListener('input', e => {
          m = Number(e.target.value);

          if (h + m + l > addition) {
            disableSubmit();
          } else {
            enableSubmit();
          }
        });

        firstLenght.addEventListener('input', e => {
          l = Number(e.target.value);

          if (h + m + l > addition) {
            disableSubmit();
          } else {
            enableSubmit();
          }
        });
      }
    }

    ////// Switching panels

    const creneau = document.getElementsByClassName("acc_creneau");
    for (let current_creneau of creneau) {

      if (current_creneau.querySelector('[class="acc_creneau_libre_link"]')) { // S'il y a un créneau effaçable
        // Empêcher de supprimer trop vite les créneaux avec un fucking missclick (yep, that happened way too many times during dev)
        const delete_link = current_creneau.querySelector('[class="acc_creneau_libre_link"]');
        let delete_link_base_content = delete_link.textContent;

        if (delete_link) {
          delete_link.addEventListener('click', e => {
            e.stopPropagation();
            if (delete_link.textContent != "Supprimer ?") {
              e.preventDefault();
            }
            delete_link.textContent = "Supprimer ?";

          });
        }

        document.body.addEventListener('click', () => {
          delete_link.textContent = delete_link_base_content;
        });
      }
      ///
      // Lien pour consulter directement la page élève : stopPropagation()
      if (document.querySelectorAll('[data-acc-link-page-eleve]')) {
        const liensToEleve = document.querySelectorAll('[data-acc-link-page-eleve]');
        for (let lienActuel of liensToEleve) {
          lienActuel.addEventListener('click', e => {
            e.stopPropagation();
          });
        }
      }

      ///

      const defaultDisplay = current_creneau.firstElementChild;
      const altDisplay = current_creneau.lastElementChild;

      if(current_creneau.querySelector('form')) {
        current_creneau.querySelector('form').addEventListener('click', e => { // Pas de switch si on clique sur le formulaire
          e.stopPropagation();
        });
      }


      // formulaire ajout élève
      if (current_creneau.querySelector('[data-acc-students]')) {

        // AJAX getStudentEns
        const student = current_creneau.querySelector('[name="acc_students"]');
        const ens = current_creneau.querySelector('[name="acc_ens"]');
        student.addEventListener('change', e => {
          getStudentEns(e.target.value, ens);
        });
      }

      const showAlt = () => {
        current_creneau.classList.add("clic_center");
        defaultDisplay.setAttribute('data-display', 'false');
        altDisplay.setAttribute('data-display', 'true');
      };
      const showDefault = () => {
        current_creneau.classList.remove("clic_center");
        defaultDisplay.setAttribute('data-display', 'true');
        altDisplay.setAttribute('data-display', 'false');
      };


      current_creneau.addEventListener('click', e => {
        e.stopPropagation();

        if (defaultDisplay.getAttribute('data-display') == "true") { // Switcher panneau au click
          showAlt();
        } else {
          showDefault();
        }
      });

      const otherCreneaux = current_creneau.parentElement.children; // Switch to default si click sur un autre créneau
      for (let autreCreneau of otherCreneaux) {
        if (autreCreneau != current_creneau) {
          autreCreneau.addEventListener('click', () => {
            showDefault();
          });
        }
      }


      document.body.addEventListener('click', () => { // Switch to default si click anywhere else
        showDefault();
      });
    }
  }

  // Changer le texte des deux boutons de settings on hover (switch activation des resa)
  if (document.getElementById('acc_enabled')) {
    const accEnabled = document.getElementById('acc_enabled');
    const accEnabledContent = accEnabled.textContent;

    accEnabled.addEventListener('mouseover', () => {
      setTimeout(function() {
        accEnabled.textContent = "Désactiver ?";
      } ,30);
    });

    accEnabled.addEventListener('mouseout', () => {
      setTimeout(function() {
        accEnabled.textContent = accEnabledContent;
      } ,30);
    });
  }

  if (document.getElementById('acc_disabled')) {
    const accEnabled = document.getElementById('acc_disabled');
    const accEnabledContent = accEnabled.textContent;

    accEnabled.addEventListener('mouseover', () => {
      setTimeout(function() {
        accEnabled.textContent = "Autoriser ?";
      } ,30);
    });

    accEnabled.addEventListener('mouseout', () => {
      setTimeout(function() {
        accEnabled.textContent = accEnabledContent;
      } ,30);
    });
  }
};

export { accompagnements };
