// Permet de gérer le bouton "facturation" sur la session élève
export const boutonFacturation = () => {
  if (document.getElementById('facturation_panel_lvl1')) {
    const bouton = document.getElementById('facturation_button');
    const panel = document.getElementById('facturation_panel_lvl1');

    bouton.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      panel.style.display = 'block';
    });

    panel.addEventListener('click', e => {
      e.stopPropagation();
    });

    document.body.addEventListener('click', () => {
      panel.style.display = 'none';
    });
  } else {
    return;
  }
};
